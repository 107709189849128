import React from "react";
// import DeployPageWrapper from "@components/DeployPageWrapper";

// import { Disclosure } from "@headlessui/react";
import Page500 from "@components/shared/Errorpages/Page500";

interface UnexpectedErrorAlertProps {
  error?: Error;
}
export const UnexpectedErrorAlert: React.FC<UnexpectedErrorAlertProps> = () => {
  // const errorMessage = error?.message;
  return <Page500 />;
};

export default UnexpectedErrorAlert;
