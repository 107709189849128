"use client"
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import HandleError from "@components/HandleError";

const GlobalError = ({ error }) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  // @ts-ignore-next-line
  return ( // @ts-ignore-next-line
    <HandleError />
  );
}

export default GlobalError;