import React from "react";

import GhostEyesIcon from "@assets/icons/GhostEyes";

import css from "../Errorpages.module.css";

const Page500 = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.topSection}>
          <div className={css.iconWrap}>
            <GhostEyesIcon />
          </div>
          <div className={css.titleCont}>
            <div className={css.title}>Error 500</div>
            <div className={css.subTitle}>Looks like something went wrong!</div>
            <div className={css.subTitle}>
              We track these errors automatically, but if the problem persists feel free to contact us. In the meantime,
              try refreshing.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Page500;
