import React from "react";

interface GhostIconInterface {
  svgFill?: string;
  width?: number;
  height?: number;
  className?: string;
}

const GhostEyesIcon = ({ width = 97, height = 96, svgFill = "none", className = "" }: GhostIconInterface) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 96 96"
      fill={svgFill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5656_321560)">
        <rect width="96" height="96" rx="48" fill="#0F0518" />
        <circle cx="48" cy="48" r="48" fill="url(#paint0_linear_5656_321560)" />
        <g filter="url(#filter0_di_5656_321560)">
          <path
            d="M35 26C26.7157 26 20 32.7157 20 41V55C20 63.2843 26.7157 70 35 70C40.5569 70 45.4081 66.9783 48 62.4883C50.5919 66.9783 55.4431 70 61 70C69.2843 70 76 63.2843 76 55V41C76 32.7157 69.2843 26 61 26C55.4431 26 50.5919 29.0217 48 33.5117C45.4081 29.0217 40.5569 26 35 26Z"
            fill="#AC5CE0"
          />
        </g>
        <rect x="20" y="26" width="30" height="44" rx="15" fill="#D3D3D3" />
        <rect x="20" y="26" width="30" height="44" rx="15" fill="url(#paint1_radial_5656_321560)" />
        <rect x="20" y="26" width="30" height="44" rx="15" fill="url(#paint2_linear_5656_321560)" />
        <rect x="20" y="26" width="30" height="44" rx="15" fill="url(#paint3_radial_5656_321560)" />
        <rect x="20" y="26" width="30" height="44" rx="15" fill="url(#paint4_radial_5656_321560)" />
        <rect x="20" y="26" width="30" height="44" rx="15" fill="url(#paint5_radial_5656_321560)" />
        <rect x="20" y="26" width="30" height="44" rx="15" fill="url(#paint6_radial_5656_321560)" />
        <rect x="20" y="26" width="30" height="44" rx="15" fill="url(#paint7_radial_5656_321560)" />
        <rect x="20" y="26" width="30" height="44" rx="15" fill="url(#paint8_radial_5656_321560)" />
        <rect x="20" y="26" width="30" height="44" rx="15" fill="url(#paint9_radial_5656_321560)" />
        <rect x="46" y="26" width="30" height="44" rx="15" fill="url(#paint10_radial_5656_321560)" />
        <rect x="46" y="26" width="30" height="44" rx="15" fill="url(#paint11_linear_5656_321560)" />
        <rect x="46" y="26" width="30" height="44" rx="15" fill="url(#paint12_radial_5656_321560)" />
        <rect x="48" y="36" width="14" height="24" rx="7" fill="url(#paint13_linear_5656_321560)" />
        <rect x="48" y="36" width="14" height="24" rx="7" fill="url(#paint14_radial_5656_321560)" />
        <rect x="22.125" y="36" width="14" height="24" rx="7" fill="url(#paint15_linear_5656_321560)" />
        <rect x="22.125" y="36" width="14" height="24" rx="7" fill="url(#paint16_radial_5656_321560)" />
        <ellipse
          cx="32.0561"
          cy="42.0142"
          rx="1.75018"
          ry="2.22253"
          transform="rotate(-39.7743 32.0561 42.0142)"
          fill="url(#paint17_radial_5656_321560)"
        />
        <ellipse
          cx="58.0483"
          cy="42.0142"
          rx="1.75018"
          ry="2.22253"
          transform="rotate(-39.7743 58.0483 42.0142)"
          fill="url(#paint18_radial_5656_321560)"
        />
        <path
          opacity="0.6"
          d="M35 26C26.7157 26 20 32.7157 20 41V55C20 63.2843 26.7157 70 35 70C40.5569 70 45.4081 66.9783 48 62.4883C50.5919 66.9783 55.4431 70 61 70C69.2843 70 76 63.2843 76 55V41C76 32.7157 69.2843 26 61 26C55.4431 26 50.5919 29.0217 48 33.5117C45.4081 29.0217 40.5569 26 35 26Z"
          stroke="#FBFBFC"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_5656_321560"
          x="-1.4737"
          y="4.5263"
          width="98.9474"
          height="86.9474"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10.7368" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.67451 0 0 0 0 0.360784 0 0 0 0 0.878431 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5656_321560" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5656_321560" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.52621" />
          <feGaussianBlur stdDeviation="10.7368" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect2_innerShadow_5656_321560" />
        </filter>
        <linearGradient id="paint0_linear_5656_321560" x1="48" y1="0" x2="48" y2="96" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AC5CE0" stopOpacity="0" />
          <stop offset="1" stopColor="#AC5CE0" stopOpacity="0.2" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.0625 37) rotate(101.077) scale(42.2879 32.7028)"
        >
          <stop stopColor="#FAF7FC" />
          <stop offset="0.456846" stopColor="#D8D0DD" />
          <stop offset="0.707884" stopColor="#D0B3E3" />
          <stop offset="1" stopColor="#CFABE4" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_5656_321560"
          x1="13.6875"
          y1="51.25"
          x2="32.9659"
          y2="53.8629"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.399485" stopColor="#9B969E" />
          <stop offset="1" stopColor="#9A959E" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(47.7695 57.0811) rotate(-133.794) scale(40.1252 43.4323)"
        >
          <stop offset="0.844296" stopColor="#9A959E" stopOpacity="0" />
          <stop offset="0.948591" stopColor="#949296" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(54.2059 55.1502) rotate(-155.387) scale(17.168 41.7431)"
        >
          <stop offset="0.0216587" stopColor="#D1AFE5" />
          <stop offset="1" stopColor="#D1AFE5" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(48.5741 52.4147) rotate(-180) scale(11.1027 31.5381)"
        >
          <stop stopColor="#ABA5B0" />
          <stop offset="1" stopColor="#ABA5B0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(53.5622 56.9202) rotate(-137.153) scale(15.1434 41.7247)"
        >
          <stop stopColor="#B689D0" />
          <stop offset="1" stopColor="#B689D0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.9154 34.1383) rotate(140.964) scale(7.02958 13.3714)"
        >
          <stop stopColor="#F3F2F4" />
          <stop offset="1" stopColor="#F3F2F4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(49 49) rotate(90) scale(20 9.15008)"
        >
          <stop stopColor="#716678" />
          <stop offset="1" stopColor="#7D7880" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(49 49) rotate(90) scale(19 5.87829)"
        >
          <stop offset="0.312473" stopColor="#402A4D" />
          <stop offset="1" stopColor="#7D7880" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(67.1875 40) rotate(103.51) scale(39.5956 30.6208)"
        >
          <stop stopColor="#FAF7FC" />
          <stop offset="0.456846" stopColor="#D8D0DD" />
          <stop offset="0.707884" stopColor="#D0B3E3" />
          <stop offset="1" stopColor="#CFABE4" />
        </radialGradient>
        <linearGradient
          id="paint11_linear_5656_321560"
          x1="39.6875"
          y1="51.25"
          x2="58.6875"
          y2="53"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.212017" stopColor="#9B969E" />
          <stop offset="1" stopColor="#9A959E" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint12_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(70.9154 34.1383) rotate(140.964) scale(7.02958 13.3714)"
        >
          <stop stopColor="#F3F2F4" />
          <stop offset="1" stopColor="#F3F2F4" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint13_linear_5656_321560" x1="55" y1="36" x2="55" y2="60" gradientUnits="userSpaceOnUse">
          <stop offset="0.354167" stopColor="#232224" />
          <stop offset="1" stopColor="#623C86" />
        </linearGradient>
        <radialGradient
          id="paint14_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(56.8125 46.75) rotate(90) scale(13.25 4.34766)"
        >
          <stop offset="0.354167" stopColor="#443753" />
          <stop offset="1" stopColor="#433750" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint15_linear_5656_321560"
          x1="29.125"
          y1="36"
          x2="29.125"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.354167" stopColor="#232224" />
          <stop offset="1" stopColor="#623C86" />
        </linearGradient>
        <radialGradient
          id="paint16_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.9375 46.75) rotate(90) scale(13.25 4.34766)"
        >
          <stop offset="0.354167" stopColor="#443753" />
          <stop offset="1" stopColor="#433750" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.0937 40.3198) rotate(127.514) scale(4.7537 5.00274)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F4F4F4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_5656_321560"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(58.0859 40.3198) rotate(127.514) scale(4.7537 5.00274)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F4F4F4" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_5656_321560">
          <rect width="96" height="96" rx="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default GhostEyesIcon;
