import React from "react";

import { ErrorBoundary } from "@sentry/nextjs";

import UnexpectedErrorAlert from "./UnexpectedErrorAlert";

interface HandleErrorProps {
  children: React.ReactNode;
}
const HandleError: React.FC<HandleErrorProps> = ({ children }) => {
  return (
    <ErrorBoundary
      fallback={({ error } : { error: any }) => {
        return <UnexpectedErrorAlert error={error} />;
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default HandleError;
